import { useState } from 'react';
import { Card, Button, Form, Col} from 'react-bootstrap';
import './IPAtoOrthography.scss';

function IPAtoOrthography () {

    const [language, setLanguage] = useState('Bosnian');
    const [ipa, setIPA] = useState(null);
    const [resultipa, setResultipa] = useState(null);

    function handleLanguageChange (event) {
        setLanguage(event.target.value);
    }

    function handleIPAChange (event) {
        setIPA(event.target.value)
    }

    function handleSubmit (event) {
        
    }

    return (
        <div>
            <Card className='appycard'>
                <div className='cardcontent'>
                    <Card.Title className='text-center appysubtitle'>Orthography to IPA</Card.Title>
                    <Form.Row className='appyformrow'>
                        <Form.Label column>
                        Choose Language:
                        </Form.Label>
                        <Col>
                            <Form.Control as="select" onChange={handleLanguageChange}>
                                <option>Bosnian</option>
                                <option>Czech</option>
                                <option>Georgian</option>
                                <option>German</option>
                                <option>Greek</option>
                                <option>Hindi</option>
                                <option>Hungarian</option>
                                <option>Italian</option>
                                <option>Korean</option>
                                <option>Malayalam</option>
                                <option>Mon</option>
                                <option>Russian</option>
                                <option>Spanish</option>
                                <option>Tamil</option>
                                <option>Telugu</option>
                                <option>Thai</option>
                                <option>Turkish</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className='appyformrow'>
                        <Form.Label column>
                        Enter IPA:
                        </Form.Label>
                        <Col>
                            <Form.Control className='forminput' type="text" placeholder="t" onChange={handleIPAChange}/>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Button className='appysubmit' onClick={handleSubmit} block>Submit</Button>
                    </Form.Row>
                    <p className='result'>>>> <span className='tempresult'><em>{resultipa}</em></span></p>
                </div>
            </Card>
        </div>
    )
}

export default IPAtoOrthography;
