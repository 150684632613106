import { useState, useEffect } from 'react';
import { Card, Button, Form, Col} from 'react-bootstrap';
import { API } from 'aws-amplify';
import './FeaturestoIPA.scss';

function FeaturestoIPA () {
    const [feat, setFeat] = useState(['+coronal','-dorsal']);

    function handleFeatChange (event) {
        setFeat(event.target.value);
    }

    function handleSubmit (event) {
        fetchResult();
    }

    const params = {
        feat: feat,
        resultfeat: '',
    }

    const [resultfeat, setResultfeat] = useState(null);

    async function fetchResult() {
        const apiData = await API.get('langwiz', '/ipa-features', {queryStringParameters: params})
        let newresult = apiData.resultfeat;
        let resultstr = ''
        for (let i=0; i<newresult.length;i++) {
            resultstr += newresult[i]+', '
        }
        
        setResultfeat(resultstr)
      }
      useEffect(() => {
        fetchResult()
      }, [])

    return (
        <div>
            <Card className='appycard'>
                <div className='cardcontent'>
                    <Card.Title className='text-center appysubtitle'>Features to IPA</Card.Title>
                    <Form.Row className='appyformrow'>
                        <Form.Label column>
                        Enter Features:
                        </Form.Label>
                        <Col>
                            <Form.Control className='forminput' type="text" placeholder="+coronal, -dorsal" onChange={handleFeatChange}/>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Button className='appysubmit' onClick={handleSubmit} block>Submit</Button>
                    </Form.Row>
                    <p className='result'>>>> <span className='tempresult'><em>{resultfeat}</em></span></p>
                </div>
            </Card>
        </div>
    )
}

export default FeaturestoIPA;
