import { useState, useEffect } from 'react';
import { Card, Button, Form, Col} from 'react-bootstrap';
import { API } from 'aws-amplify';
import './IPAtoFeatures.scss';

function IPAtoFeatures () {
    const [ipa, setIPA] = useState(['p', 't', 'k']);

    function handleIPAChange (event) {
        setIPA(event.target.value);
    }

    function handleSubmit (event) {
        fetchResult();
    }

    const params = {
        ipa: ipa,
        resultipa: '',
    }

    const [resultipa, setResultipa] = useState(null);

    async function fetchResult() {
        const apiData = await API.get('langwiz', '/ipa-features', {queryStringParameters: params})
        let newresult = apiData.resultipa
        let resultjsx = [];
        for (let i=0; i < newresult.length; i++) {
            if (newresult[i].slice(-1) !== '2') {
                if (i === 0) {
                    resultjsx.push(<p className='text-center'>>>> [{newresult[i]}]</p>)
                } else {
                    resultjsx.push(<p className='text-center'>[{newresult[i]}]</p>)
                }
            }
            
        }
        setResultipa(resultjsx)
      }
      useEffect(() => {
        fetchResult()
      }, [])

    return (
        <div>
            <Card className='appycard'>
                <div className='cardcontent'>
                    <Card.Title className='text-center appysubtitle'>IPA to Features</Card.Title>
                    <Form.Row className='appyformrow'>
                        <Form.Label column>
                        Enter IPA:
                        </Form.Label>
                        <Col>
                            <Form.Control className='forminput' type="text" placeholder="p, t, k" onChange={handleIPAChange}/>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Button className='appysubmit' onClick={handleSubmit} block>Submit</Button>
                    </Form.Row>
                    <p className='result'><span className='tempresult'>{resultipa}</span></p>
                </div>
            </Card>
        </div>
    )
}

export default IPAtoFeatures;
