import { useState, useEffect } from 'react';
import { Card, Button, Form, Col} from 'react-bootstrap';
import { API } from 'aws-amplify';
import './OrthographytoIPA.scss';

function OrthographytoIPA () {

    const [langname, setLanguage] = useState('Bosnian');
    const [orth, setOrthography] = useState(null);

    function handleLanguageChange (event) {
        setLanguage(event.target.value);
    }

    function handleOrthographyChange (event) {
        setOrthography(event.target.value)
    }

    function handleSubmit (event) {
        fetchResult();
    }

    const params = {
        langname: langname,
        orth: orth,
        ipa: '',
        resultorth: '',
        resultipa: '',
    }

    const [resultorth, setResultorth] = useState(null);

    async function fetchResult() {
        const apiData = await API.get('langwiz', '/ipa-orthography', {queryStringParameters: params})
        setResultorth(apiData.resultorth)
      }
      useEffect(() => {
        fetchResult()
      }, [])

    return (
        <div>
            <Card className='appycard'>
                <div className='cardcontent'>
                    <Card.Title className='text-center appysubtitle'>Orthography to IPA</Card.Title>
                    <Form.Row className='appyformrow'>
                        <Form.Label column>
                        Choose Language:
                        </Form.Label>
                        <Col>
                            <Form.Control as="select" onChange={handleLanguageChange}>
                                <option>Bosnian</option>
                                <option>Czech</option>
                                <option>Georgian</option>
                                <option>German</option>
                                <option>Greek</option>
                                <option>Hindi</option>
                                <option>Hungarian</option>
                                <option>Italian</option>
                                <option>Korean</option>
                                <option>Malayalam</option>
                                <option>Mon</option>
                                <option>Russian</option>
                                <option>Spanish</option>
                                <option>Tamil</option>
                                <option>Telugu</option>
                                <option>Thai</option>
                                <option>Turkish</option>
                            </Form.Control>
                        </Col>
                    </Form.Row>
                    <Form.Row className='appyformrow'>
                        <Form.Label column>
                        Enter Orthography:
                        </Form.Label>
                        <Col>
                            <Form.Control className='forminput' type="text" placeholder="t" onChange={handleOrthographyChange}/>
                        </Col>
                    </Form.Row>
                    <Form.Row>
                        <Button className='appysubmit' onClick={handleSubmit} block>Submit</Button>
                    </Form.Row>
                    <p className='result'>>>> <span className='tempresult'><em>{resultorth}</em></span></p>
                </div>
            </Card>
        </div>
    )
}

export default OrthographytoIPA;
