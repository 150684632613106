import { Col, Container, Row, Card, Form, Button } from 'react-bootstrap';
import './IPAFeatures.scss';
import IPAtoFeatures from './IPAtoFeatures/IPAtoFeatures';
import FeaturestoIPA from './FeaturestoIPA/FeaturestoIPA';

function IPAOrthography () {
    return (
        <div>
            <Card.Title className='text-center appytitle'>IPA ⟷ Features</Card.Title>
            <Container>
                <Row>
                    <Col>
                        <IPAtoFeatures />
                    </Col>
                    <Col>
                        <FeaturestoIPA />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default IPAOrthography;