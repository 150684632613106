import { Col, Container, Row, Card, Form, Button } from 'react-bootstrap';
import './IPAOrthography.scss';
import OrthographytoIPA from './OrthographytoIPA/OrthographytoIPA';
import IPAtoOrthography from './IPAtoOrthography/IPAtoOrthography';

function IPAOrthography () {
    return (
        <div>
            <Card.Title className='text-center appytitle'>IPA ⟷ Orthography</Card.Title>
            <Container>
                <Row>
                    <Col>
                        <OrthographytoIPA />
                    </Col>
                    <Col>
                        <IPAtoOrthography />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default IPAOrthography;