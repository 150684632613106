
import React from 'react';
import './PhoneticsFive.scss';
import { Tabs, Tab, ListGroup, Container, Image, Col, Row } from 'react-bootstrap';

class PhoneticsFive extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <div>
                <div className='lessoncontent'>
                    <h1 className='modulesubtitle text-center'>TITLE</h1>
        			<p className="lessontext subone">1.5 Vowels are the most sonorous sounds, involving the most minimal vocal tract constrictions.</p>
					<p className="lessontext subtwo">1.5.1 Vowels are differentiated from each other in three main ways, based on articulator position:</p>
					<p className="lessontext subthree">1.5.1.1 Height refers to the up/down dimension within the mouth. Say 'a' followed by 'u'. Do you feel the jaw move up and down? This is vowel height. Vowels like 'i' and 'u' are high (aka close), while 'a' is low (aka open). There are also mid vowels, such as 'e' and 'o'.</p>
					<p className="lessontext subthree">1.5.1.2 Frontness/Backness is the front/back dimension within the mouth. Say 'i' followed by 'u'. Do you feel the tongue move front and back? This is backness. Vowels like 'i' and 'e' are front, while 'u' and 'o' are back. There are also central vowels.</p>
					<p className="lessontext subthree">1.5.1.3 (Lip) Rounding refers to the relative rounding of the lips in the binary distinction rounded or unrounded. Say 'u' followed by 'i' and pay attention to the position of the lips. The 'u' vowel is rounded while 'i' is unrounded.</p>
					<p className="lessontext subtwo">1.5.2 Tense/lax distinguishes a relatively tense position of the mouth vs. a relaxed one. The vowel in 'be' is tense, while the one in 'bit' is lax.</p>
					<p className="lessontext subtwo">1.5.3 Length: Vowels may be distinguished by length, short vs. long. English only does this marginally, but vowel length is very important in languages such as Latin and Hungarian.</p>
					<p className="lessontext subtwo">1.5.4 Nasality: Vowels may be nasalized, with air flow through the nasal cavity, controlled by the position of the velum. The vowel in the English word 'ten' is nasalized. In languages such as French and Portuguese, vowel nasality is very impotrant in determining meaning.</p>
					<p className="lessontext subtwo">1.5.5 Diphthongs are sequences of vowels that act as one sound, such as 'au'.</p>
					<p className="lessontext subtwo">1.5.6 Consonants may act as vowels, in which case they are known as syllabic consonants. More on this when we talk about syllables.</p>
            </div>
        </div>
        )
    }
}

export default PhoneticsFive;